import React from "react";
import SiteMap from "../components/FrontStatic/SiteMap";
import SEO from "../components/SEO";

const Sitemap = (props) =>
    <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/>
    <SiteMap {...props}/>
</React.Fragment>;

export default Sitemap

export const query = graphql`
  query SitemapQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "sitemap-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;