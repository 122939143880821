import React, {Component} from 'react';
import FrontFooter from './common/Front-Footer';
import FrontWrapper from './common/FrontWrapper';
import IntlMessages from '../../util/IntlMessages';
// import SEO from '../../components/SEO';
import Suggestion from './Suggestion';
import {Modal} from 'antd';
import {HeaderAction} from '../../constants/Traker';
import {Link} from "gatsby";

/* eslint jsx-a11y/anchor-is-valid: 0 */

class SiteMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        window.track(HeaderAction.invantory.open);
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
        window.track(HeaderAction.invantory.close);
    };

    render() {
        return (
            <FrontWrapper location={this.props.location}>
                <div className='scroll-content-wrapper' data-scrollbar>
                    <div className='page-main page-current'>
                        <div className='page-toload legacy-page' data-bodyclassname='legacy'>
                            <main className='page-content' role='main'>
                                <section className='splash sitemap relative mb-50'>
                                    <div className='container sitemap-container'>
                                        <h1 className='primary-text'>
                                            <IntlMessages id='app.Site-Map'/>
                                        </h1>
                                    </div>
                                </section>
                                <section>
                                    <div className='container sitemap-container'>
                                        <div className='d-flex mb-50 mar-b-xs-0 flex-wrap-xs sitemap-content-box'>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.about'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <Link to='/'>
                                                            <IntlMessages id='app.AboutHK'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/why-hk'>
                                                            <IntlMessages id='app.WhyHK'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/customer-testimonials'>
                                                            <IntlMessages id='app.CustomerTestimonials'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/policy'>
                                                            <IntlMessages id='app.Policy'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/news-media'>
                                                            <IntlMessages id='app.HKNews'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/help'>
                                                            <IntlMessages id='app.Help'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/corporate-social-responsibility'>
                                                            <IntlMessages id='app.CSRatHK'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/hk-achievements'>
                                                            <IntlMessages id='app.hkachievement'/>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.Educational'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <Link to='/4cs'>
                                                            <IntlMessages id='app.4cs'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/cut-and-polished-diamonds'>
                                                            <IntlMessages id='app.Cut_and_Polished_Diamonds'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/wholesale-diamonds'>
                                                            <IntlMessages id='app.Wholesale_Diamonds'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/certified-diamonds'>
                                                            <IntlMessages id='app.Certified_Diamonds'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/loose-diamonds'>
                                                            <IntlMessages id='app.Loose_Diamonds'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/fancy-diamonds'>
                                                            <IntlMessages id='app.Fancy_Diamonds'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/HK-grading'>
                                                            <IntlMessages id='app.HKGrading'/>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.customerCareDashboard'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/customer-care/appointment`}>
                                                            <IntlMessages id='app.customerCare.BookAppointment'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href='https://www.gia.edu/' target='_blank'
                                                           rel="noopener noreferrer">
                                                            <IntlMessages id='app.GIAReportCheck'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/customer-care/calendar`}>
                                                            <IntlMessages id='app.customerCare.eventCalendar'/>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <Link to='/terms-and-condition'>
                                                            <IntlMessages id='app.signup.temsCondition'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/customer-care/FAQs'>
                                                            <IntlMessages id='app.customerCare.faqs'/>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='d-flex mb-50 mar-b-xs-0 flex-wrap-xs sitemap-content-box'>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.UserAccount'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-account`}>
                                                            <IntlMessages id='app.myAccount'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-watchlist`}>
                                                            <IntlMessages id='app.watch-list'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-enquiry`}>
                                                            <IntlMessages id='app.EnquiryButton'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-cart`}>
                                                            <IntlMessages id='app.Cart'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-order`}>
                                                            <IntlMessages id='app.Order'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}`}>
                                                            <IntlMessages id='app.Purchase'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/recommended`}>
                                                            <IntlMessages id='app.IAmLucky'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/diamond-search`}>
                                                            <IntlMessages id='app.SearchHistory'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/settings`}>
                                                            <IntlMessages id='app.settings'/>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.HKExclusive'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}new-diamonds`}>
                                                            <IntlMessages id='app.newArrival'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}`}>
                                                            <IntlMessages id='app.stoneOfTheDay'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/my-saved-search`}>
                                                            <IntlMessages id='app.FavoriteSearch'/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/best-of-hk`}>

                                                            <IntlMessages id='app.bestHkDiamonds'/>

                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='sitemap-content'>
                                                <h2>
                                                    <IntlMessages id='app.UsefulLinks'/>
                                                </h2>
                                                <ul>
                                                    {/*{!localStorage.getItem("hkw-token") && (*/}
                                                        <li>
                                                            <a href={`${process.env.GATSBY_REDIRECT_URL}/signup`}>
                                                                <IntlMessages id='app.signup.registration'/>
                                                            </a>
                                                        </li>
                                                    {/*// )}*/}
                                                    {/*{!localStorage.getItem("hkw-token") && (*/}
                                                        <li>
                                                            <a href={`${process.env.GATSBY_REDIRECT_URL}/signin`}>
                                                                <IntlMessages id='app.Guest.loginButton'/>
                                                            </a>
                                                        </li>
                                                    {/*)}*/}
                                                    <li>
                                                        <Link to='/customer-care/concierge-services'>
                                                            <IntlMessages id='app.customerCare.conciergeServices'/>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to='/contact'>
                                                            <IntlMessages id='app.customerCare.contact'/>
                                                        </Link>
                                                    </li>
                                                   {/*
                                                    <li>
                                                        <a href='/career'>
                                                            <IntlMessages id='app.Careers'/>
                                                        </a>
                                                    </li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='d-flex mb-50 mar-b-xs-0 flex-wrap-xs sitemap-content-box'>
                                            <div className='sitemap-content mb-xs-0'>
                                                <h2>
                                                    <IntlMessages id='app.Feedback'/>
                                                </h2>
                                                <ul>
                                                    <li>
                                                        <a onClick={this.showModal}>
                                                            <IntlMessages id='app.Suggestion'/>
                                                        </a>
                                                        <Modal
                                                            title={[
                                                                <h2 className='modal__title primary-text'>
                                                                    <IntlMessages id='app.Suggestion'/>
                                                                </h2>,
                                                            ]}
                                                            visible={this.state.visible}
                                                            onCancel={this.handleCancel}
                                                            footer={null}>
                                                            <Suggestion handleCancel={() => this.handleCancel()}/>
                                                        </Modal>
                                                    </li>
                                                     <li>
                                                        <Link to='/feedback'>
                                                            <IntlMessages id='app.Howdoyoulikeourwebsite?' />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <FrontFooter/>
                            </main>
                            <div className='preload' aria-hidden='true'/>
                        </div>
                    </div>
                    <div className='page-main page-next' aria-hidden='true'/>
                    <div className='page-main page-prev' aria-hidden='true'/>
                    <footer className='footer' role='contentinfo'/>
                    {/*<SEO url={this.props.location.pathname.split('/')[1]} />*/}
                </div>
            </FrontWrapper>
        );
    }
}

export default SiteMap;
